const insertIntoFooter = () => {
  const mainFooter = document.getElementById("main-footer");
  const navFooter = mainFooter.querySelector(".navigation .cmp-navigation__group");
  //Selecting all components with add-to-footer paintbrush
  const addToFooter = mainFooter.querySelectorAll(".add-to-footer");

  //Adding each component with add-to-footer paintbrush to the footer navigation
  addToFooter.forEach((element) => {
    element.classList.add("d-block");
    navFooter.prepend(element);
  });
};

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", insertIntoFooter);
} else {
  // `DOMContentLoaded` already fired
  insertIntoFooter();
}
