const calendarUpdate = () => {
    const getWeek = ({ today }) => {
        const curr = new Date();
        let week = [];

        for (let i = 1; i <= 7; i++) {
            let first = curr.getDate() - curr.getDay() + i,
            day = new Date(curr.setDate(first));

            week.push({
                dayLong: day.toLocaleDateString("en-US", {
                    weekday: "long",
                }),
                dayShort: day.toLocaleDateString("en-US", {
                    weekday: "short",
                }),
                yearLong: day.getFullYear(),
                day: day.getDate(),
                month: day.getMonth() + 1,
                year: parseInt(day.getFullYear().toString().slice(-2)),
                date:
                    day.getMonth() +
                    1 +
                    "/" +
                    day.getDate() +
                    "/" +
                    day.getFullYear().toString().slice(-2),
                isToday: today.getDate() === day.getDate(),
            });
        }
        return week;
    };

    let jsonCache = null;
    const loadWOCCFullCalendar = async ({ yearLong }) => {
        if (!jsonCache) {
            const result = await fetch("/content/dam/wocc/us/en/calendars/calendar_" + yearLong + ".json");
            jsonCache = result.json();
        }
        return jsonCache;
    };

    const getWOCCWeekCalendar = async ({ yearDates, currWeek }) => {
        return yearDates.map((calDay) => {
            const inWeek = currWeek.filter(
                (week) => week.date.toString() === calDay.date.toString()
            );
            if (inWeek.length > 0) {
                return {
                    weekDayShort: inWeek[0].dayShort,
                    weekDayLong: inWeek[0].dayLong,
                    openTime: calDay.openTime.toString().trim(),
                    closeTime: calDay.closeTime.toString().trim(),
                    lastEntry: calDay.lastEntry.toString().trim(),
                    // Current Day callout
                    date: calDay.date.toString().trim(),
                    isToday: inWeek[0].isToday,
                };
            }
            return null;
        })
        .filter((calDay) => calDay);
    };

    const getWOCCHoursWeek = async ({ today }) => {
        const currWeek = getWeek({ today }),
            fullCalendar = await loadWOCCFullCalendar({yearLong: today.getFullYear(),}),
            weekCalendar = await getWOCCWeekCalendar({yearDates: fullCalendar.dates, currWeek, });
        return weekCalendar;
    };

    const generateMarkup = (weekData) => {
        let markup = "";
        weekData.forEach((day) => {
            markup += "<tr>";
            //Slice removes the year.
            markup +=
                day.openTime.toLowerCase() !== "closed"
                    ? "<td>" + day.date.slice(0, -3) + "</td>"
                    : "<td>&nbsp;</td>";
            markup += "<td>" + day.weekDayShort + "</td>";
            markup += "<td>" + day.openTime + "</td>";
            markup += "<td> &#8211; </td>";
            markup +=
                day.openTime.toLowerCase() !== "closed"
                    ? "<td>" + day.closeTime + "</td>"
                    : "";
            //lastEntry is optonal, and currently not in use.
            // markup += (day.openTime.toLowerCase() !== 'closed') ? '<td>' + day.lastEntry + '</td>' : '';
            markup += "</tr>";
        });
        return markup;
    };
    
    // Footer Formatter
    const formatFooter = ({ weekData }) => {
        const footerTableTBody = document.querySelectorAll(".hours-footer tbody");
        footerTableTBody.forEach((table) => {
            table.innerHTML = generateMarkup(weekData);
        });
    };
    
    // Mid Section Formatter
    const formatMidSection = ({ weekData }) => {
        const hoopTableBody = document.querySelectorAll(".hours-middle table tbody");
        hoopTableBody.forEach((table) => {
            table.innerHTML = generateMarkup(weekData);
        });
    };
    

    // Current Day snippet
    const formatCurrentDay = ({ weekData }) => {
        const currDayBody = document.querySelectorAll('.hours-header .cmp-text p')[1],
            day = weekData.find((week) => week.isToday);

        if(!currDayBody) return; //In case there is no navigation

        if (day) {
            let markup = "";
            markup +=
                day.openTime.toLowerCase() !== "closed"
                    ? day.openTime + " - " + day.closeTime
                    : day.openTime;
            currDayBody.innerHTML = markup;
        }
    };

    const init = async () => {
        const today = new Date(),
            woccWeeksHours = await getWOCCHoursWeek({ today });
        // Format footer with information
        formatFooter({ weekData: woccWeeksHours });

        // Format menu and midSection with information
        formatMidSection({ weekData: woccWeeksHours });

        // Format current day
        formatCurrentDay({ weekData: woccWeeksHours });
    };
    init();
};

if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", calendarUpdate);
} else {  // `DOMContentLoaded` already fired
    calendarUpdate();
}