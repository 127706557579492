import { isAuthorMode } from "../../utils/isAuthorMode";

const popUpConfig = () => {
    const popUp = document.querySelector(".teaser-pop-up");
    if (!popUp || isAuthorMode()) return;

    const popUpHeader = popUp.querySelector(".cmp-teaser__image");
    const popUpButtons = popUp.querySelectorAll(".cmp-teaser__action-link");
    const pageBody = document.body;

    // Creation of close button
    const closeButton = document.createElement("span");
    closeButton.textContent = "x";
    closeButton.classList.add("close-popup");
    popUpHeader?.appendChild(closeButton);

    // Shows the pop-up and prevents page scroll
    if (!sessionStorage.getItem("hide-pop-up")) {
        pageBody.classList.add("pop-up-open");
        popUp.classList.remove("d-none");
    }

    [closeButton, ...popUpButtons].forEach((button) => {
        button.addEventListener("click", (e) => {
            pageBody.classList.remove("pop-up-open");
            popUp.classList.add("d-none");
            sessionStorage.setItem("hide-pop-up", "true");
        });
    });
};

if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", popUpConfig);
} else {
    // `DOMContentLoaded` already fired
    popUpConfig();
}
