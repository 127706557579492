export const CONTENT_WIDTH = Object.freeze({
    TABLET: 880,
    DESKTOP: 1120,
});

export const BREAKPOINT = Object.freeze({
    MOBILE: 0,
    TABLET: 600,
    DESKTOP: 1200,
});
