import { Navigation, Pagination, SwiperOptions } from 'swiper';
import { BREAKPOINT } from '../../utils/variables';

export const swiperConfigurations: { [key: string]: SwiperOptions } = {
    primary: {
        modules: [Navigation, Pagination],
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            bulletElement: 'button',
        },
        navigation: {
            nextEl: '.swiper__button-next',
            prevEl: '.swiper__button-prev',
        },
        loop: true,
        spaceBetween: 12,
        slidesPerView: 1.1,
        centeredSlides: true,
        breakpoints: {
            [BREAKPOINT.DESKTOP]: {
                spaceBetween: 32,
                slidesPerView: 2,
                centeredSlides: false,
            },
        },
    },
    secondary: {
        modules: [Navigation, Pagination],
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            bulletElement: 'button',
        },
        navigation: {
            nextEl: '.swiper__button-next',
            prevEl: '.swiper__button-prev',
        },
        loop: true,
        spaceBetween: 12,
        slidesPerView: 1.1,
        centeredSlides: true,
        breakpoints: {
            [BREAKPOINT.DESKTOP]: {
                spaceBetween: 32,
                slidesPerView: 3,
                centeredSlides: false,
            },
        },
    },
    hero: {
        modules: [Pagination],
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            bulletElement: 'button',
        },
        loop: true,
        spaceBetween: 12,
        slidesPerView: 1.1,
        centeredSlides: true,
        breakpoints: {
            [BREAKPOINT.DESKTOP]: {
                spaceBetween: 24,
            },
        },
    },
    image: {
        modules: [Navigation, Pagination],
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            bulletElement: 'button',
        },
        loop: true,
        spaceBetween: 8,
        slidesPerView: 1.1,
        centeredSlides: true,
        breakpoints: {
            [BREAKPOINT.DESKTOP]: {
                spaceBetween: 24,
            },
        },
    },
    card: {
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: '.swiper__button-next',
            prevEl: '.swiper__button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            bulletElement: 'button',
        },
        slidesPerView: 1,
        centeredSlides: true,
        lazy: {
            enabled: true,
            checkInView: true,
            loadOnTransitionStart: true,
        },
    },
    micro: {
        modules: [Navigation],
        loop: false,
        slidesPerView: 'auto',
        breakpoints: {
            [BREAKPOINT.MOBILE]: {
                navigation: {
                    enabled: false,
                },
            },
            [BREAKPOINT.DESKTOP]: {},
        },
    },
};
