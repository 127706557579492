window.onload = function () {

    // Mobile Accordion
    const navigationLevel0 = document.querySelectorAll(".cmp-navigation__item--level-0");
    const headerRightDropdown = document.querySelectorAll('.header-right-dropdown');

    function accordion(param) {
        param.forEach((param) => {
            param.addEventListener("click", () => {
                if (param.classList.contains("is-open")) {
                    param.classList.remove("is-open");
                } else {
                    const paramsWithIsOpen = document.querySelectorAll(".is-open");
                    paramsWithIsOpen.forEach((paramWithIsOpen) => {
                        paramWithIsOpen.classList.remove("is-open");
                    });
                    param.classList.add("is-open");
                }
            });
        });
    }

    accordion(navigationLevel0);
    accordion(headerRightDropdown)
    

    const buttonMobileMenu = document.querySelector('#main-header > .button');

    buttonMobileMenu.onclick = function () {
        buttonMobileMenu.classList.toggle('open-menu');
    }
    // End mobile accordion

    // Navigation banners logic start
    // Matches each banner with the respective navigation menu
    const navBanners = (id, navItemNumber) => {
        const item = document.getElementById(`${id}`);
        const navItem = document.querySelector(`#main-navigation > .cmp-navigation__group li:nth-child(${navItemNumber+1}) .cmp-navigation__group .cmp-navigation__item:last-child`)
        if (item && navItem) navItem.after(item.parentNode);
    }
    // Array items must be in the same order, we need them to appear in the navigation
    const bannerIds = ["table-header", "explore", "groups", "book-event-space", "about-us"];

    bannerIds.forEach((bannerId, index) => {
        navBanners(bannerId, index);
    })
    // Navigation banners logic end

    // Teaser top banner logic
    const teaserTopBanner = document.querySelector('.teaser-top-banner .cmp-teaser__action-link');
    if (teaserTopBanner) {
        teaserTopBanner.onclick = function (e) {
            e.preventDefault();
            document.querySelector(".teaser-top-banner").style.display = "none";
        }
    }
};
