import { BREAKPOINT } from './variables';

function isMobileMedia(): boolean {
    return matchMedia(`only screen and (min-width: ${BREAKPOINT.MOBILE}px)`).matches;
}

function isTabletMedia(): boolean {
    return matchMedia(`only screen and (min-width: ${BREAKPOINT.TABLET}px)`).matches;
}

function isDesktopMedia(): boolean {
    return matchMedia(`only screen and (min-width: ${BREAKPOINT.DESKTOP}px)`).matches;
}

export function isMobile(): boolean {
    return isMobileMedia() && !isTabletMedia() && !isDesktopMedia();
}

export function isTablet(): boolean {
    return isTabletMedia() && !isDesktopMedia();
}

export function isDesktop(): boolean {
    return isDesktopMedia();
}

export function getDesktopBreakpoint(): number {
    return BREAKPOINT.DESKTOP;
}
