const loadIframeSrc = () => {
  const experienceContainers = document.querySelectorAll('.cmp-embed__third-party-experience');
  if (!experienceContainers.length) return;

  experienceContainers.forEach((experienceContainer) => {
    const iframeElement = experienceContainer.querySelector('iframe');
    iframeElement.setAttribute('src', iframeElement.dataset.src);
  });
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", loadIframeSrc);
} else {
  loadIframeSrc();
}

