import Swiper from 'swiper';
import 'swiper/swiper.scss';
import { isDesktop } from '../../utils/breakpoints';
import isAuthor from '../../utils/isAuthor';
import { isRtl } from '../../utils/isRtl';
import { BREAKPOINT } from '../../utils/variables';
import { swiperConfigurations } from './carousel.config';

interface GraniteMessage {
    data?: {
        id: string;
        type: string;
        operation: string;
        index: number;
    };
}

interface GraniteMessageChannel {
    subscribeRequestMessage: (target: string, callback: (message: GraniteMessage) => void) => void;
}

type CarouselType = 'primary' | 'secondary' | 'hero' | 'image' | 'card' | 'micro';

const CLASSES = {
    overflown: 'overflown',
    overflownStart: 'overflown--start',
    overflownEnd: 'overflown--end',
    scrollWrapper: 'cmp-carousel__scroll',
    scrollWrapperPrev: 'cmp-carousel__scroll--prev',
    scrollWrapperNext: 'cmp-carousel__scroll--next',
    scrollFade: 'cmp-carousel__scroll-fade',
    scrollButton: 'cmp-carousel__scroll-button',
    carouselItem: 'cmp-carousel__item',
};

(() => {
    let carouselIndicatorsLabel = '';
    let prevButtonLabel = '';
    let nextButtonLabel = '';

    const onDocumentReady = () => {
        const carousels = findCarousels();
        if (isAuthor) {
            initAuthorCarousels(carousels);
        } else {
            initPublishCarousels(carousels);
        }
    };

    const findCarousels = (): NodeListOf<HTMLElement> =>
        document.querySelectorAll<HTMLElement>('[data-cmp-is="carousel"]');

    const initAuthorCarousels = (carousels: NodeListOf<HTMLElement>) => {
        carousels.forEach((carousel) => initAuthorCarousel(carousel));
        listenToCarouselsAuthoring();
    };

    const initAuthorCarousel = (carousel: HTMLElement) => {
        removeIdentifier(carousel);
        removeNativeControls(carousel);
        navigateToPanel(carousel, 0);
        listenToPanelChanges(carousel);
    };

    const removeIdentifier = (carousel: HTMLElement): void => {
        // eslint-disable-next-line max-len
        // based on https://github.com/adobe/aem-core-wcm-components/blob/2cf13773be8e345b14e5081943d50cc235a001b1/content/src/content/jcr_root/apps/core/wcm/components/carousel/v1/carousel/clientlibs/site/js/carousel.js#L118
        // prevents unwanted repeated initialisations via mutation observer
        carousel.removeAttribute('data-cmp-is');
    };

    const removeNativeControls = (carousel: HTMLElement): void => {
        // Not needed in onexp carousel
        const carouselNavigation = carousel.querySelector('.cmp-carousel__actions');
        const carouselIndicators = carousel.querySelector('.cmp-carousel__indicators');
        carouselIndicatorsLabel = carouselIndicators?.ariaLabel || '';

        if (carouselNavigation) {
            prevButtonLabel = carouselNavigation.querySelector(
                '.cmp-carousel__action--previous .cmp-carousel__action-text'
            )?.textContent;
            nextButtonLabel = carouselNavigation.querySelector(
                '.cmp-carousel__action--next .cmp-carousel__action-text'
            )?.textContent;

            carouselNavigation.remove();
        }

        carouselIndicators?.remove();
    };

    const navigateToPanel = (carousel: HTMLElement, index: number): void => {
        if (carousel.closest('.carousel--microcarousel')) return;

        const items = carousel.querySelectorAll<HTMLElement>('.cmp-carousel__item');

        items.forEach((item, i) => {
            if (i === index) {
                item.classList.add('cmp-carousel__item--active'); // needed by core components carousel's editorhook
                item.classList.remove('hidden');
                item.removeAttribute('aria-hidden');
            } else {
                item.classList.remove('cmp-carousel__item--active');
                item.classList.add('hidden');
                item.setAttribute('aria-hidden', String(true));
            }
        });
    };

    // eslint-disable-next-line max-len
    // based on https://github.com/adobe/aem-core-wcm-components/blob/2cf13773be8e345b14e5081943d50cc235a001b1/content/src/content/jcr_root/apps/core/wcm/components/carousel/v1/carousel/clientlibs/site/js/carousel.js#L136
    // This connects component's markup with aem's authoring overlays
    const listenToPanelChanges = (carousel: HTMLElement): void => {
        const channel = getGraniteMessageChannel();

        if (channel) {
            channel.subscribeRequestMessage('cmp.panelcontainer', (message) => {
                if (
                    message.data &&
                    message.data.type === 'cmp-carousel' &&
                    message.data.id === carousel.dataset['cmpPanelcontainerId'] &&
                    message.data.operation === 'navigate'
                ) {
                    navigateToPanel(carousel, message.data.index);
                }
            });
        }
    };

    const getGraniteMessageChannel = (): GraniteMessageChannel | undefined => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.Granite?.author?.MessageChannel) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return new window.Granite.author.MessageChannel('cqauthor', window) as GraniteMessageChannel;
        } else {
            return undefined;
        }
    };

    // eslint-disable-next-line max-len
    // based on https://github.com/adobe/aem-core-wcm-components/blob/2cf13773be8e345b14e5081943d50cc235a001b1/content/src/content/jcr_root/apps/core/wcm/components/carousel/v1/carousel/clientlibs/site/js/carousel.js#L708
    // When author adds/removes/reorders items in carousel, the whole carousel component is removed
    // and inserted in the markup again, so we need to init it again
    const listenToCarouselsAuthoring = (): void => {
        const observer = new MutationObserver((mutations) => {
            getAddedCarousels(mutations).forEach((carousel) => initAuthorCarousel(carousel));
        });

        observer.observe(document.body, {
            subtree: true,
            childList: true,
            characterData: true,
        });
    };

    const getAddedCarousels = (mutations: MutationRecord[]): HTMLElement[] => {
        return mutations
            .flatMap((m) => Array.from(m.addedNodes))
            .map((node) => (isElementNode(node) ? node : null))
            .filter((element) => !!element)
            .flatMap(() => Array.from(findCarousels()));
    };

    // reference: https://dom.spec.whatwg.org/#dom-node-nodetype
    const isElementNode = (node: Node): node is Element => node.nodeType === 1;

    const initPublishCarousels = (carousels: NodeListOf<HTMLElement>) => {
        carousels.forEach((carousel) => initSwiperCarousel(carousel));
    };

    const switchChildFocus = (carousel: HTMLElement) => {
        const carouselSlides = carousel.querySelectorAll('.cmp-carousel__item');
        carouselSlides.forEach((slide) => {
            const slideAnchors = slide.querySelectorAll('a');
            const slideButtons = slide.querySelectorAll('button');
            const slideSize = slide.getBoundingClientRect();
            const carouselSize = carousel.getBoundingClientRect();
            if (slideSize.left >= carouselSize.left && slideSize.right <= carouselSize.right) {
                slide.ariaHidden = 'false';
                slideAnchors.forEach((element) => {
                    element.tabIndex = 0;
                });
                slideButtons.forEach((element) => {
                    element.tabIndex = 0;
                });
            } else {
                slide.ariaHidden = 'true';
                slideAnchors.forEach((element) => {
                    element.tabIndex = -1;
                });
                slideButtons.forEach((element) => {
                    element.tabIndex = -1;
                });
            }
            slide.setAttribute('role', 'group');
        });

        const paginationBullets = carousel.querySelectorAll('.swiper-pagination-bullet');

        paginationBullets.forEach((bullet) => {
            if (bullet.classList.contains('swiper-pagination-bullet-active')) {
                bullet.ariaCurrent = 'true';
            } else {
                bullet.ariaCurrent = 'false';
            }
        });
    };

    const createNavigationButton = (carouselId: string, className: string) => {
        const button = document.createElement('button');
        button.setAttribute('aria-live', 'polite');
        button.setAttribute('aria-controls', `${carouselId}`);
        button.className = className;

        return button;
    };

    const addNavigation = (carousel: HTMLElement, carouselId: string) => {
        const prevButton = createNavigationButton(carouselId, 'swiper__button-prev');
        const nextButton = createNavigationButton(carouselId, 'swiper__button-next');
        isRtl() ? carousel.append(nextButton, prevButton) : carousel.append(prevButton,nextButton);
    };

    const createPagination = (carousel: HTMLElement, carouselId: string) => {
        const pagination = document.createElement('div');
        pagination.classList.add('swiper-pagination', `swiper-pagination--${carouselId}`);
        pagination.setAttribute('role', 'navigation');
        pagination.ariaLabel = carouselIndicatorsLabel;
        carousel.append(pagination);
    };

    const initSwiperCarousel = (carousel: HTMLElement) => {
        removeNativeControls(carousel);
        const carouselId = carousel.id;
        const carouselItems = carousel.querySelectorAll('.cmp-carousel__item');
        // Do not initialise Swiper if Carousel is empty - may cause bugs
        if (carouselItems.length === 0) {
            console.warn(`Empty carousel detected - ${carouselId}`);
            return;
        }

        // Set swiper structure
        carousel.classList.add('swiper', `swiper--${carouselId}`);
        carousel.querySelector('.cmp-carousel__content')?.classList.add('swiper-wrapper');
        carouselItems.forEach((el) => el.classList.add('swiper-slide'));

        // Add navigation & pagination if more than 1 slide - navigation hidden by default
        if (carouselItems.length > 1) {
            addNavigation(carousel, carouselId);
            createPagination(carousel, carouselId);
        }

        // Select config for carousel
        let carouselType: CarouselType = 'primary';
        let isUsingPagination = true;

        if (carousel.closest('.carousel--card-secondary')) {
            carouselType = 'secondary';
        } else if (carousel.closest('.carousel--hero')) {
            carouselType = 'hero';
        } else if (carousel.closest('.carousel--image')) {
            carouselType = 'image';
        } else if (carousel.closest('.feed-item')) {
            carouselType = 'card';
        } else if (carousel.closest('.carousel--microcarousel')) {
            carouselType = 'micro';
            isUsingPagination = false;
        }

        const slidesPerGroupAmount = () => {
            if (!isDesktop()) return 1;

            const scrollWrappers = carousel.querySelectorAll(`.${CLASSES.scrollWrapper}`);
            const tileWidth = 116;
            let scrollWrappersTotalWidth = 0;

            scrollWrappers.forEach((scrollWrapper) => {
                scrollWrappersTotalWidth += scrollWrapper.clientWidth;
            });

            // if after breakpoint change two scroll wrappers are visible, nothing should be subtracted at the end
            return (
                Math.floor((carousel.clientWidth - scrollWrappersTotalWidth) / tileWidth) -
                (scrollWrappersTotalWidth > 80 ? 0 : 1)
            );
        };

        const initScrollWrappers = (): HTMLElement[] => {
            const directions = ['prev', 'next'] as const;

            const buttons = directions.map((direction) => {
                const scrollWrapperDirectionClass = `${CLASSES.scrollWrapper}--${direction}`;

                const scrollWrapper = document.createElement('div');
                scrollWrapper.classList.add(CLASSES.scrollWrapper, scrollWrapperDirectionClass);

                const scrollFade = document.createElement('div');
                scrollFade.classList.add(CLASSES.scrollFade);

                const scrollButton = document.createElement('button');
                scrollButton.classList.add(CLASSES.scrollButton);
                if (direction === 'prev') {
                    scrollButton.ariaLabel = isRtl() ? nextButtonLabel : prevButtonLabel;
                } else {
                    scrollButton.ariaLabel = isRtl() ? prevButtonLabel : nextButtonLabel;
                }

                scrollWrapper.appendChild(scrollButton);
                scrollWrapper.appendChild(scrollFade);

                carousel.appendChild(scrollWrapper);

                return scrollButton;
            });

            return isRtl() ? [buttons[1], buttons[0]] : [buttons[0], buttons[1]];
        };

        const isOverflown = (carousel: Element, side: 'start' | 'end') => {
            if (carousel.childElementCount === 0) return false; // empty carousel with microcarousel variant case

            const { firstElementChild: carouselContentWrapper } = carousel;
            const { firstElementChild: firstEl, lastElementChild: lastEl } = carouselContentWrapper;
            const firstElRect = firstEl.getBoundingClientRect();
            const lastElRect = lastEl.getBoundingClientRect();
            const carouselRect = carousel.getBoundingClientRect();

            if (isRtl()) {
                return side === 'start' ? firstElRect.right > carouselRect.right : lastElRect.left < carouselRect.left;
            } else {
                return side === 'start' ? firstElRect.left < carouselRect.left : lastElRect.right > carouselRect.right;
            }
        };

        const handleOverflow = (): void => {
            let isOverflownToStart = isOverflown(carousel, 'start');
            let isOverflownToEnd = isOverflown(carousel, 'end');

            carousel.classList.toggle(CLASSES.overflown, isOverflownToStart || isOverflownToEnd);

            // CSS 'overflown' class adjusts flexbox positioning - distances need to be recalculated
            isOverflownToStart = isOverflown(carousel, 'start');
            isOverflownToEnd = isOverflown(carousel, 'end');

            carousel.classList.toggle(CLASSES.overflownStart, isOverflownToStart);
            carousel.classList.toggle(CLASSES.overflownEnd, isOverflownToEnd);
        };

        // Swiper was breaking navigation arrows on RTL
        // So, instead of giving him full control of navigation, we call selected methods directly on its instance
        const changeMicrocarouselSlide = (slidePrev: () => void, slideNext: () => void, ev: MouseEvent) => {
            if (!swiper) return;

            const parentClassList = (ev.currentTarget as HTMLElement).parentElement.classList;

            if (parentClassList.contains(CLASSES.scrollWrapperPrev)) {
                slidePrev();
            } else if (parentClassList.contains(CLASSES.scrollWrapperNext)) {
                slideNext();
            }
        };

        const setDeepLinkInitialSlide = () => {
            const qs = new URLSearchParams(window.location.search);
            const carouselDeepLinkId = `carousel-${qs.get('carousel-id')}`;
            const slideDeepLink = Number(qs.get('slide'));
            const slidesAmount = carousel.querySelectorAll(`.${CLASSES.carouselItem}`).length;

            if (carouselDeepLinkId === carouselId && !isNaN(slideDeepLink) && slidesAmount > slideDeepLink) {
                swiperConfigurations[carouselType].initialSlide = slideDeepLink;
            }
        };

        if (carouselType === 'micro') {
            handleOverflow();

            // dynamic update for Microcarousel config
            const buttons = initScrollWrappers();
            const slidePrev = () => {
                swiper.slidePrev();
            };
            const slideNext = () => {
                swiper.slideNext();
            };

            for (const button of buttons) {
                button.addEventListener('click', (ev) => {
                    changeMicrocarouselSlide(slidePrev, slideNext, ev);
                });
            }

            swiperConfigurations[carouselType].breakpoints[BREAKPOINT.DESKTOP].slidesPerGroup = slidesPerGroupAmount();
            swiperConfigurations[carouselType].breakpoints[BREAKPOINT.DESKTOP].navigation = {
                enabled: true,
            };
        }

        setDeepLinkInitialSlide();

        // Init swiper
        const swiper = new Swiper(`.swiper--${carouselId}`, {
            ...swiperConfigurations[carouselType],
            ...(isUsingPagination && {
                pagination: {
                    el: `.swiper-pagination--${carouselId}`,
                    clickable: true,
                    bulletElement: 'button',
                },
            }),
        });

        // Initialize events for microcarousel
        if (carouselType === 'micro') {
            swiper.on('transitionEnd', () => {
                handleOverflow();
            });

            swiper.on('breakpoint', (sw) => {
                sw.params.slidesPerGroup = slidesPerGroupAmount();
                sw.update();
            });
        }

        // Watch for slide visibility change
        switchChildFocus(carousel);
        swiper.on('transitionEnd', () => switchChildFocus(carousel));
    };
    
    if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
      } else {
        onDocumentReady();
      }
})();
