const hideTitleOnVideo = () => {
  const xfHeader = document.querySelector('.cmp-experiencefragment--header');
  if (!xfHeader) return;

  const titleHeader = xfHeader.querySelector('.title-header');
  const heroVideo = document.querySelector('.container-hero-video');
  if (!heroVideo || !titleHeader) return;
  
  titleHeader.style.display = "none";
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", hideTitleOnVideo);
} else {
  hideTitleOnVideo();
}