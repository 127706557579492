import { loadYouTubeApi } from '../../utils/loadYouTubeApi';

const SELECTORS = { 
  youtubeEmbed: '.cmp-embed__youtube',
  thumbnailButton: 'cmp-embed__load-button',
  youtubeIframe: 'cmp-embed__youtube-iframe',
  picture: 'picture',
  hidden: 'hidden'
}

const checkForYouTubeEmbed = () => { 
  return document.querySelector(SELECTORS.youtubeEmbed) ? true : false;
};

class YouTubeEmbed {
  constructor(embedComponent) {
    this.embedComponent = embedComponent;
    this.playerData = JSON.parse(embedComponent.dataset.iframe);
    this.picture = embedComponent.querySelector(SELECTORS.picture);
    this.loadButton;
    this.playerIframe;
    this.player;
    this.loadedVideo = false;

    this.init();
  }

  hideStaticPicture() {
    this.loadButton.classList.add(SELECTORS.hidden);
    this.picture.classList.add(SELECTORS.hidden);
  }

  getPlayerId() {
    const path = new URL(this.playerData.src).pathname.split('/');
    return `youtube-player-${path.at(-1)}`;
  }

  loadVideo() {
    this.hideStaticPicture();

    this.playerIframe = document.createElement('iframe');
    this.playerIframe.classList.add(SELECTORS.youtubeIframe);
    this.playerIframe.title = this.playerData.playerTitle;
    this.playerIframe.src = this.playerData.src;
    this.playerIframe.setAttribute('frameborder', '0');
    this.playerIframe.allow = this.playerData.allow;
    this.playerIframe.ariaLabel = this.playerData.playerAriaLabel;
    this.playerIframe.id = this.getPlayerId();
    this.embedComponent.appendChild(this.playerIframe);

    this.player = new YT.Player(this.playerIframe.id,{
      events: {
        onReady: (event) => {
          event.target.playVideo();
        }
      }
    });

  }

  init() {
    const playButton = document.createElement('span');
    this.embedComponent.style.paddingBottom = this.playerData.iFrameAspectRatio;
    this.loadButton = document.createElement('button');
    this.loadButton.append(playButton)
    this.loadButton.className = SELECTORS.thumbnailButton;
    this.embedComponent.append(this.loadButton);
    this.loadButton.addEventListener('click', () => {
      if (!this.loadedVideo) {
        this.loadVideo();
        this.loadedVideo = true;
        return;
      }
      // possible method here for full screen etc
    })
  }
}


const initYouTube = () => {
  loadYouTubeApi()
    .then(() => {
      const embeds = document.querySelectorAll(SELECTORS.youtubeEmbed);
      embeds.forEach((embedComponent) => {
        new YouTubeEmbed(embedComponent);
      });
    })
};

const onDocumentReady = () => {
  if (!checkForYouTubeEmbed()) return;
  initYouTube();
};

if (document.readyState !== 'loading') {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}